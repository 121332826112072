import { create } from "zustand";

export const useFormDataStore = create((set) => ({
   formData: {},
   setFormDataEmail: (e, fieldName) => {
      handleEmail(e,fieldName,set,"formData");
   },
   setFormDataTelephone: (e, fieldName) => {
      handleTelephone(e,fieldName,set,"formData");
   },
   setFormDataInput: (e, fieldName) => {
      handleInput(e,fieldName,set,"formData");
   },
   setFormDataStatic: (name, value) => {
      set((prevData) => ({
         formData: {
            ...prevData["formData"],
            [name]: value
         }
      }))      
   },
   setFormData: (val) => {
      set(() => ({
         formData: val
      }))
   }
}));

export const useCustomerInfoStore = create((set) => ({
   customerInfo: {},
   setCustomerInfoEmail: (e, fieldName) => {
      handleEmail(e, fieldName, set, "customerInfo");
   },
   setCustomerInfoTelephone: (e, fieldName) => {
      handleTelephone(e, fieldName, set, "customerInfo");
   },
   setCustomerInfoInput: (e, fieldName) => {
      handleInput(e, fieldName, set, "customerInfo");
   },
   setCustomerInfo: (val) => {
      set(() => ({
         customerInfo: val
      }))
   }
}));

const handleEmail = (e, fieldName, set, type) => {
   const { value, name } = e.target;

   set((prevData) => {
      const emailFieldMap = {
         emailAddress: 'emailAddress',
         emailDomain: 'emailDomain',
         emailAddressConfirmation: 'emailAddressConfirmation',
         emailDomainConfirmation: 'emailDomainConfirmation',
         authCode: 'authCode',
         isVerified: 'isVerified',
         isInitialLoading: 'isInitialLoading',
      };

      let email = prevData[type][fieldName];

      if (emailFieldMap.hasOwnProperty(name)) {
         email = {
            ...email,
            [emailFieldMap[name]]: value
         };
      }

      return {
         [type]: {
            ...prevData[type],
            [fieldName]: email,
         }
      };
   });
};

const handleTelephone = (e,fieldName,set,type) => {
   const { value, name } = e.target;
      set((prevData) => {
         let phoneNumbers = {};

         switch (name) {
         case "PhoneNumber1":
            phoneNumbers = {
               ...prevData[type][fieldName],
               phoneNumber1: value,
            };
            break;
         case "PhoneNumber2":
            phoneNumbers = {
               ...prevData[type][fieldName],
               phoneNumber2: value,
            };
            break;
         case "PhoneNumber3":
            phoneNumbers = {
               ...prevData[type][fieldName],
               phoneNumber3: value,
            };
            break;
         default:
            phoneNumbers = prevData[type][fieldName];
            break;
         }

         return {
            [type]: {
               ...prevData[type],
               [fieldName]: phoneNumbers
            }
         };
      })
}
const handleInput = (e,fieldName,set,type) => {
   if(fieldName){
      set((prevData) => ({
         [type]: {
            ...prevData[type],
            [fieldName]: e
         }
      }))
   } else {
      const { name, value } = e.target;
      set((prevData) => ({
         [type]: {
            ...prevData[type],
            [name]: value
         }
      }))
   }
}